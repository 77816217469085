import { tokenUtil } from '@utils/token';
import { apiConstant } from '@apis/constant';
import { fetch } from '@apis/ServiceController';
import { adminService } from './adminService';
import { dispatch } from '@store/store';
import Request from './utils/Request';
import { FETCH_FEEDBACK_CATEGORIES } from '../constants/actions';

const createFeedback = async (body, cancelToken, isAdmin) => {
   const formData = new FormData();
   console.log(body);
   if (body) {
      Object.keys(body).forEach((key) => {
         if (key === 'attachments') {
            console.log(body[key]);
            body[key].forEach((file) => {
               formData.append(key, file);
            });
         } else {
            formData.append(key, body[key]);
         }
      });
   }
   return fetch({
      method: 'post',
      url: apiConstant.CREATE_FEEDBACK,
      data: formData,
      headers: {
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
      errorHandler: isAdmin ? adminService.handleError : void 0,
   });
};

const getFeedbackMatrix = async (body, cancelToken) => {
   return fetch({
      method: 'post',
      url: apiConstant.GET_FEEDBACK_MATRIX,
      data: body,
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
   });
};

const getFeedbackDetails = async (uid, cancelToken) => {
   return fetch({
      method: 'get',
      url: apiConstant.GET_FEEDBACK_DETAILS,
      params: {
         uid,
      },
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
   });
};

const resolveFeedbacks = async (uids, cancelToken) => {
   return fetch({
      method: 'put',
      url: apiConstant.RESOLVE_FEEDBACKS,
      data: {
         uids,
      },
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
   });
};

const getFeedbackCategoryMatrix = async (body, cancelToken) => {
   return fetch({
      method: 'post',
      url: apiConstant.GET_FEEDBACK_CATEGORY_MATRIX,
      data: body,
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
   });
};

const createFeedbackCategory = async (body, cancelToken) => {
   return fetch({
      method: 'post',
      url: apiConstant.CREATE_FEEDBACK_CATEGORY,
      data: body,
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
   });
};

const updateFeedbackCategory = async (uid, body, cancelToken) => {
   return fetch({
      method: 'put',
      url: apiConstant.UPDATE_FEEDBACK_CATEGORY,
      data: body,
      params: {
         uid,
      },
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
   });
};

const deleteFeedbackCategories = async (uids, cancelToken) => {
   return fetch({
      method: 'delete',
      url: apiConstant.DELETE_FEEDBACK_CATEGORIES,
      data: {
         uids,
      },
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
   });
};

const loadFeedbackCategories = async (body) => {
   return await dispatch(
      Request.post(FETCH_FEEDBACK_CATEGORIES, {
         data: body,
      })
   );
};

export const feedbackService = {
   createFeedback,
   getFeedbackMatrix,
   getFeedbackDetails,
   resolveFeedbacks,
   getFeedbackCategoryMatrix,
   createFeedbackCategory,
   updateFeedbackCategory,
   deleteFeedbackCategories,
   loadFeedbackCategories,
};
