import React from 'react';

const IconArrowNext = () => (
   <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M15.3536 8.35363C15.5488 8.15837 15.5488 7.84178 15.3536 7.64652L10 2.29297L9.29289 3.00008L13.7929 7.50008H1V8.50008H13.7929L9.29289 13.0001L10 13.7072L15.3536 8.35363Z"
         fill="currentColor"
      />
   </svg>
);

export default IconArrowNext;
