import { createCancelToken } from '@apis/ServiceController';
import SEO from '@components/SEO';
import { feedbackService } from '@services/feedbackService';
import { convertToDate } from '@utils/common';
import constant from '@utils/constant';
import Search from '@views_admin/layout/Search';
import { Link } from 'gatsby';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import _capitalize from 'lodash/capitalize';
import _lowerCase from 'lodash/lowerCase';
import { FeedbackStatus } from '@utils/enums/FeedbackStatus';
import IconCheckDone from '@icons/IconCheckDone';
import { Tooltip } from 'antd';
import CommonTable from '@views_admin/components/CommonTable';
import FilterFeedback from '@views_admin/feedback/FilterFeedback';
import Sort from '@views_admin/components/Sort';
import { SortType } from '@apis/enums/SortType';
import ContentLoading from '@views_admin/ContentLoading';
import IconArrowNext from '@icons/IconArrowNext';

const sortOptions = [
   {
      label: 'Last modified',
      value: 'updated_at',
   },
   {
      label: 'Date created',
      value: 'created_at',
   },
   {
      label: 'Sender',
      value: 'full_name',
   },
   {
      label: 'Category',
      value: 'category_name',
   },
   {
      label: 'Status',
      value: 'status',
   },
];

const Feedback = memo(() => {
   const [searchText, setSearchText] = useState('');
   const [filter, setFilter] = useState({
      page: 1,
      page_size: constant.defaultPagesize,
      order_by: 'updated_at',
      sort_type: SortType.DESC,
      status: '',
      role: '',
      category_uid: '',
      from_date: '',
      to_date: '',
   });
   const [data, setData] = useState({
      content: [],
      totalRows: 0,
   });
   const [loading, setLoading] = useState(false);
   const cancelToken = useRef();

   useEffect(() => {
      const loadData = async () => {
         if (cancelToken.current) {
            cancelToken.current.cancel();
         }
         await setLoading(true);
         cancelToken.current = createCancelToken();
         const res = await feedbackService.getFeedbackMatrix(
            { ...filter, search: searchText.trim() },
            cancelToken.current?.token
         );
         if (res.isSuccess) {
            const { content, totalRows } = res.data;
            setData((prevData) => ({
               ...prevData,
               content: content || [],
               totalRows: totalRows,
            }));
         }
         setSearchText(searchText.trim());
         setLoading(false);
      };

      loadData();

      return () => {
         if (cancelToken.current) {
            cancelToken.current.cancel();
         }
      };
   }, [filter, searchText]);

   const onTableChange = useCallback(({ pagination, sorter }) => {
      setFilter((prevFilter) => ({
         ...prevFilter,
         page: pagination?.page || prevFilter.page,
         page_size: pagination?.page_size || prevFilter.page_size,
      }));
   }, []);

   const columns = useMemo(() => {
      const resolveFeedback = async (uid) => {
         const response = await feedbackService.resolveFeedbacks([uid]);
         if (response.isSuccess) {
            setFilter((prevFilter) => ({
               ...prevFilter,
            }));
         }
      };
      return [
         {
            title: 'Content',
            dataIndex: 'content',
            render: (text, record) => (
               <Link
                  to={`${constant.ROUTE_ADMIN_FEEDBACK}/${record.uid}`}
                  title="View feedback"
                  className="description event-url"
               >
                  {text || ''}
               </Link>
            ),
         },
         {
            title: 'Sender',
            dataIndex: 'full_name',
         },
         {
            title: 'Sent date',
            dataIndex: 'created_at',
            render: (text) => <span>{text ? convertToDate(text) : ''}</span>,
         },
         {
            title: 'Category',
            dataIndex: 'category',
            render: (v) => (
               <span>{v?.name ? v.name : <span className="nothing">--</span>}</span>
            ),
         },
         {
            title: 'Role',
            dataIndex: 'role',
            render: (v) => <span>{_capitalize(_lowerCase(v))}</span>,
         },
         {
            title: 'Status',
            dataIndex: 'status',
            render: (text) => (
               <span className={`feedback-stt ${text}`}>
                  {_capitalize(_lowerCase(text))}
               </span>
            ),
         },
         {
            title: 'Action',
            key: 'action',
            width: 116,
            render: (text, record) => (
               <div className="admin-col-action">
                  <Tooltip placement="bottom" title="View" color="black">
                     <Link
                        className="button-icon action-normal"
                        to={`${constant.ROUTE_ADMIN_FEEDBACK}/${record.uid}`}
                     >
                        <IconArrowNext />
                     </Link>
                  </Tooltip>
                  {record?.status !== FeedbackStatus.RESOLVED && (
                     <Tooltip placement="bottom" title="Resolve" color="black">
                        <button
                           className="button-icon action-green"
                           onClick={() => resolveFeedback(record.uid)}
                        >
                           <IconCheckDone />
                        </button>
                     </Tooltip>
                  )}
               </div>
            ),
         },
      ];
   }, []);

   const handleFilter = useCallback((newFilter = {}) => {
      setFilter((prevFilter) => ({
         ...prevFilter,
         ...newFilter,
         page: 1,
      }));
   }, []);

   const handleSort = useCallback((orderBy, sortType) => {
      setFilter((prevFilter) => ({
         ...prevFilter,
         sort_type: sortType,
         order_by: orderBy,
      }));
   }, []);

   const filterSet = useMemo(() => {
      return {
         status: filter.status,
         role: filter.role,
         category_uid: filter.category_uid,
         from_date: filter.from_date,
         to_date: filter.to_date,
      };
   }, [filter]);

   return (
      <>
         <SEO title={'Feedback - Admin'} />
         <div className="content-head">
            <div className="content-head__left">
               <div className="admin-shipment__tabs">
                  <div className={`tab-item active`}>Feedback</div>
                  <Link
                     className={`tab-item`}
                     to={`${constant.ROUTE_ADMIN_FEEDBACK_CATEGORIES}`}
                  >
                     Feedback Categories
                  </Link>
               </div>
            </div>
         </div>
         <div className="content-body admin-products__body">
            <div className="admin-table">
               <ContentLoading isLoading={loading} />
               <div className="admin-table__header">
                  <div className="admin-table__header--left">
                     <Search placeholder="Search..." onSearch={(v) => setSearchText(v)} />
                  </div>
                  <div className="admin-table__header--right">
                     <FilterFeedback filterSet={filterSet} onChange={handleFilter} />
                     <Sort
                        orderBy={filter.order_by}
                        sortType={filter.sort_type}
                        options={sortOptions}
                        onChange={handleSort}
                     />
                  </div>
               </div>
               <CommonTable
                  rowKey="uid"
                  unit="Feedbacks"
                  dataSource={data.content}
                  columns={columns}
                  page={filter.page}
                  pageSize={filter.page_size}
                  totalRows={data.totalRows}
                  onChange={onTableChange}
               />
            </div>
         </div>
      </>
   );
});

export default Feedback;
