import React from 'react';

const IconCheckDone = ({ isActive }) => (
   <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      {isActive ? (
         <>
            <path
               d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
               fill="#27AE60"
            />
            <path
               d="M5.35355 7.64645L5 7.29289L4.29289 8L4.64645 8.35355L5.35355 7.64645ZM7 10L6.64645 10.3536L7 10.7071L7.35355 10.3536L7 10ZM11.3536 6.35355L11.7071 6L11 5.29289L10.6464 5.64645L11.3536 6.35355ZM4.64645 8.35355L6.64645 10.3536L7.35355 9.64645L5.35355 7.64645L4.64645 8.35355ZM7.35355 10.3536L11.3536 6.35355L10.6464 5.64645L6.64645 9.64645L7.35355 10.3536Z"
               fill="white"
            />
         </>
      ) : (
         <>
            <path
               d="M15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8Z"
               stroke="currentColor"
            />
            <path
               d="M5.35355 7.64645L5 7.29289L4.29289 8L4.64645 8.35355L5.35355 7.64645ZM7 10L6.64645 10.3536L7 10.7071L7.35355 10.3536L7 10ZM11.3536 6.35355L11.7071 6L11 5.29289L10.6464 5.64645L11.3536 6.35355ZM4.64645 8.35355L6.64645 10.3536L7.35355 9.64645L5.35355 7.64645L4.64645 8.35355ZM7.35355 10.3536L11.3536 6.35355L10.6464 5.64645L6.64645 9.64645L7.35355 10.3536Z"
               fill="currentColor"
            />
         </>
      )}
   </svg>
);

export default IconCheckDone;
